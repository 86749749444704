<template>
  <EditColumn
    submitText="Create Record"
    title="Create a Record"
    :loading="addStatus === 'adding'"
    :error="addError"
    @submit="handleSubmit"
    @cancel="handleCancel"
  />
</template>

<script>
// @ is an alias to /src
import EditColumn from './Components/EditColumn'
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'AddRecord',
  components: {
    EditColumn,
  },
  computed: {
    ...mapState('clients/client/records', [
      'addStatus',
      'addError',
      'recordId',
    ]),
  },
  methods: {
    ...mapActions('clients/client/records', ['addRecord']),
    ...mapMutations('clients/client/records', ['CLEAR_ADD_ERROR']),
    handleCancel() {
      this.CLEAR_ADD_ERROR()
      let nextRoute = `/clients/${this.$route.params.clientId}`
      if (this.recordId) {
        nextRoute = nextRoute + `/record/${this.recordId}`
      }
      this.$router.push(nextRoute)
    },
    async handleSubmit(r) {
      await this.addRecord(r)
      if (this.addError === '') {
        this.$router.push(this.recordId)
      }
    },
  },
}
</script>
