<template>
  <form @submit.prevent="handleSubmit">
    <div v-if="error" id="form-error" class="text-error text-tiny mb-4">
      {{ error }}
    </div>
    <div v-if="success" id="form-success" class="text-success text-tiny mb-4">
      {{ success }}
    </div>
    <slot></slot>
  </form>
</template>

<script>
export default {
  name: 'ToznyForm',
  methods: {
    handleSubmit(e) {
      return this.loading ? false : this.onSubmit(e)
    },
  },
  props: {
    error: String,
    success: String,
    loading: {
      type: Boolean,
      default: false,
    },
    onSubmit: Function,
  },
}
</script>
